<template>
    <div class="container">
        <div class="main">
            <div class="content">
                <div class="content_l">
                    <div>
                        <h3>{{ info.article?.title }}</h3>
                        <div class="nav">
                            <span>{{ formatData(info.article?.updated_at) }}</span>
                            <span>来源：{{ info.article?.category }}</span>
                            <span>浏览：{{ info.article?.views }}</span>
                        </div>
                    </div>
                    <div class="center" v-html="info.article?.content"></div>
                    <div class="footer">
                        <div><span @click="changToDetail(info.prev.article_id)" v-if="info.prev">上一篇</span><span>{{ info.prev?.title }}</span></div>
                        <div><span @click="changToDetail(info.next.article_id)" v-if="info.next">下一篇</span><span>{{ info.next?.title }}</span></div>
                    </div>
                </div>
                <div class="content_r">
                    <div class="title">热门资讯</div>
                    <div class="hot">
                        <span v-for="(item,index) in info.hot_articles" :key="index" @click="changToDetail(item.article_id)">{{ item.title }}</span>
                    </div>
                    <div class="title">最新活动</div>
                    <div class="card_list">
                        <div class="card_list_item" v-for="(item,index) in info.latest_events" :key="index" @click="changToDetail(item.article_id)">
                            <div class="card_top">
                                <img v-lazy="item.cover_image">
                            </div>
                            <div class="card_footer">
                                <span class="text">{{ item.title }}</span>
                                <span class="time">{{ formatData(item.updated_at) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loading v-if="isLoading"/>
    </div>
</template>
<script>
import { getNewsDetail } from '@/api/news'
import moment from 'moment';
export default {
    name: 'newsDetail',
    data() {
        return {
            info: {},
            isLoading: true
        }
    },
    mounted() {
        let id = this.$route.query.id
        this.initData(id)
    },
    methods: {
        async initData(id) {
            try {
                const { data:res } = await getNewsDetail(id)
                this.info = res
            }finally {
                this.isLoading = false
            }
        },
        changToDetail(id) {
            this.$router.push(`/newsDetail?id=${id}`)
        }
    },
    computed: {
        formatData() {
            return (date) => {
                return moment(date).format('YYYY-MM-DD')
            }
        }
    },
    watch: {
        $route(n) {
            this.initData(n.query.id)
        }
    }
}
</script>
<style scoped lang="scss">
$width: 1600px;
.container {
    box-sizing: border-box;
    width: 100vw;
    height: auto;
    background-color: #f5f5f5;
    .main {
        width: $width;
        margin: 0 auto;
        padding: 20px 50px 60px 50px;
        box-sizing: border-box;
        .content {
            display: flex;
            column-gap: 20px;
            &_l {
                flex: 1;
                background: #fff;
                padding: 40px 30px 20px 30px;
                box-sizing: border-box;
                color: #333333;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                h3 {
                    font-size: 24px;
                }
                .nav {
                    color: #999;
                    display: flex;
                    font-size: 14px;
                    margin: 18px 0 40px 0;
                    display: flex;
                    column-gap: 40px;
                }
                .center {
                    flex: 1;
                    margin-bottom: 20px;
                    font-size: 14px;
                    ::v-deep img {
                        width: 100%!important;
                    }
                }
                .footer {
                    padding-top: 20px;
                    box-sizing: border-box;
                    border-top: 1px solid #EEEEEE;
                    display: flex;
                    flex-direction: column;
                    row-gap: 16px;
                    div {
                        display: flex;
                        column-gap: 20px;
                        align-items: center;
                        span:nth-child(1) {
                            font-size: 16px;
                            color: #2D8BFF;
                            cursor: pointer;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        span:nth-child(2) {
                            font-size: 14px;
                            color: #666666;
                        }
                    }
                }
            }
            &_r {
                // position: sticky;
                // top: 0;
                width: 360px;
                .title {
                    color: #333333;
                    font-weight: bold;
                    font-size: 18px;
                    margin-bottom: 20px;
                }
                .hot {
                    width: 100%;
                    padding: 30px 20px;
                    box-sizing: border-box;
                    color: #666;
                    background: #fff;
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    margin-bottom: 30px;
                    font-size: 14px;
                    span {
                        white-space: nowrap; /* 禁止换行 */
                        overflow: hidden; /* 隐藏溢出内容 */
                        text-overflow: ellipsis; /* 显示省略号 */
                        cursor: pointer;
                        &:hover {
                            color: #FFA71D;
                        }
                    }
                }
                .card_list {
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    &_item {
                        background: #fff;
                        cursor: pointer;
                        .card_top {
                            overflow: hidden;
                            height: 200px;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: all 0.3s ease;
                                &:hover {
                                    transform: scale(1.1);
                                }
                            }
                        }
                        .card_footer {
                            padding: 16px 20px;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            row-gap: 15px;
                            .text {
                                color: #333333;
                                font-size: 16px;
                                font-weight: bold;
                                &:hover {
                                    color: #FFA71D;
                                }
                            }
                            .time {
                                color: #999999;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
